
import { Vue } from 'vue-class-component';
import { NavItem, NavGroup } from '@/interfaces';

export default class ProgressIndicator extends Vue {
  groupClasses(group: NavGroup): object {
    return {
      'tw-border-blue-800': !(group.active || group.wasActive),
      'tw-border-blue-200': group.active || group.wasActive,
    };
  }

  moduleClasses(module: NavItem): object {
    return {
      'tw-bg-blue-800': !(module.active || module.wasActive),
      'tw-bg-blue-200': module.active || module.wasActive,
    };
  }

  questionClasses(module: NavItem, question: NavItem): object {
    return {
      'tw-bg-blue-800': !(question.active || question.wasActive),
      'tw-bg-blue-200': (module.wasActive && question.wasActive) || (module.active && question.active),
      'tw-bg-green-500': module.active && question.wasActive,
    };
  }

  get navItems(): Map<string, NavGroup> {
    return this.$store.getters['exam/navItems'];
  }
}

export interface AudioElements {
  [key: string]: HTMLAudioElement;
}

export interface State {
  currentSource: string | null;
  currentModuleSources: string[];
  audioElements: AudioElements;
  timer: null | ReturnType<typeof setTimeout>;
}

const initialState: State = {
  currentSource: null,
  currentModuleSources: [],
  audioElements: {},
  timer: null,
};

export default {
  namespaced: true,
  state: () => initialState,
  actions: {
    async sleep({ state }: { state: State }, s: number | null | undefined) {
      return new Promise(resolve => {
        state.timer = window.setTimeout(resolve, s! * 1000);
      });
    },
    addAudio({ state }: { state: State }, src: string) {
      const audioElement = new Audio(src);
      audioElement.load();
      state.audioElements[src] = audioElement;
    },
    stopAudio({ state }: { state: State }) {
      const audioElement = state.audioElements[state.currentSource!];
      if (audioElement) {
        state.currentSource = null;
        audioElement.pause();
        audioElement.currentTime = 0;
      }
    },
    setAudioSources({ state, dispatch }: any, sources: string[]) {
      clearTimeout(state.timer);
      state.currentModuleSources = sources;
      dispatch('stopAudio');
      dispatch('playAudio', 0);
    },
    async playAudio(
      { state, dispatch, rootGetters }: { state: State; dispatch: any; rootGetters: any },
      current: number,
    ) {
      if (state.currentSource !== null) {
        await dispatch('stopAudio');
      }

      const audioElement = state.audioElements[state.currentModuleSources[current]];

      if (!audioElement) {
        return;
      }

      state.currentSource = state.currentModuleSources[current];

      if (rootGetters['getDelay']) {
        await dispatch('sleep', rootGetters['getDelay']);
      }

      try {
        await new Promise(res => {
          audioElement.play();
          audioElement.onended = res;
        });

        dispatch('playAudio', current + 1);
        state.currentSource = null;
      } catch (e) {
        state.currentSource = null;
      }
    },
  },
};

<template>
  <logger-provider>
    <router-view></router-view>
  </logger-provider>
</template>

<script>
import { LoggerProvider } from '@/providers';

export default {
  components: { LoggerProvider },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78c846de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-w-full" }
const _hoisted_2 = {
  key: 0,
  class: "tw-h-1 tw-bg-black"
}
const _hoisted_3 = { class: "tw-flex tw-justify-evenly" }
const _hoisted_4 = { class: "module-title tw-inline-block tw-absolute tw-px-2 tw-mx-auto tw-uppercase tw-bg-primary" }
const _hoisted_5 = { class: "tw-flex" }
const _hoisted_6 = { class: "tw-flex reset-padding-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems.values(), (group) => {
        return (_openBlock(), _createElementBlock("li", {
          key: group,
          class: _normalizeClass(["tw-relative tw-inline-block tw-p-10 tw-py-2 tw-mx-4 tw-border tw-text-center", _ctx.groupClasses(group)])
        }, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(group.name), 1),
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.modules, (module) => {
              return (_openBlock(), _createElementBlock("li", {
                key: module,
                class: "tw-mx-5"
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["tw-h-7 tw-w-7 tw-mx-auto tw-rounded-full", _ctx.moduleClasses(module)])
                }, null, 2),
                _createElementVNode("ul", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(module.questions, (question) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: question,
                      class: _normalizeClass(["tw-h-3 tw-w-3 tw-m-1 tw-bg-black tw-rounded-full", _ctx.questionClasses(module, question)])
                    }, null, 2))
                  }), 128))
                ])
              ]))
            }), 128))
          ])
        ], 2))
      }), 128))
    ])
  ]))
}
export enum SessionStatus {
  STARTING = 'STARTING',
  STARTED = 'STARTED',
  FAILED = 'FAILED',
  FINISHING = 'FINISHING',
  FINISHED = 'FINISHED',
  NOT_STARTED = 'NOT_STARTED',
}

interface Session {
  connected?: boolean;
  status?: SessionStatus;
  examId?: string;
  daily?: {
    roomUrl: string;
    clientToken: string;
  };
  deviceInformation: {
    browser?: string;
    os?: string;
    device?: {
      type?: string;
      brand?: string;
      model?: string;
    }
  };
}

export enum ParticipantStatus {
  Connected = 'CONNECTED',
  NotConnected = 'NOT_CONNECTED',
}

interface Participant {
  status?: ParticipantStatus;
  audio?: boolean;
  video?: boolean;
  userId?: string;
  videoTrack?: {
    persistentTrack: MediaStreamTrack;
    state: string;
    track: MediaStreamTrack;
  };
}

interface Participants {
  local?: Participant;
  student?: Participant;
}

interface Daily {
  state: string;
  studentStatus: string;
  participants: Participants;
}

export interface State {
  examiner: Participant;
  daily: Daily;
  data: Session;
}

function getSession(state: State) {
  return state;
}

export default {
  namespaced: true,

  state: () => ({
    examiner: {
      status: ParticipantStatus.NotConnected,
      audio: false,
      video: false,
    },
    data: {
      status: undefined,
      daily: undefined,
      examId: undefined,
      deviceInformation: {
        browser: '',
        os: '',
        device: {
          type: '',
          brand: '',
          model: '',
        },
      },
    },
  }),

  getters: {
    getSession,
  },

  mutations: {
    updateExaminer(state: State, examiner: Participant) {
      state.examiner = examiner;
    },

    updateSession(state: State, data: Session) {
      state.data = {
        ...state.data,
        ...data,
      };
    },
  },
};

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  computed: mapState({
    session: state => state.session.data,
    daily: state => state.session.daily,
    examiner: state => state.session.examiner,
  }),
  data() {
    return {
      loading: false,
      examScreen: null,
      participants: {},
      examinerVideoSource: null,
    };
  },
  mounted() {
    this.handleExaminerVideo();
  },
  methods: {
    handleExaminerVideo() {
      if (!this.examiner?.videoTrack?.persistentTrack) {
        return;
      }

      this.examinerVideoSource = new MediaStream([this.examiner?.videoTrack?.persistentTrack]);
    },
  },
  watch: {
    examiner: {
      handler() {
        this.handleExaminerVideo();
      },
    },
  },
};
</script>

<template>
  <video autoPlay playsInline :srcObject.prop="examinerVideoSource" />
</template>

<style></style>

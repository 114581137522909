<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <header class="tw-flex-grow-0">
      <div class="tw-flex tw-justify-between">
        <h1 class="tw-text-5xl tw-mx-5 tw-text-left tw-overflow-ellipsis">
          {{ view.title }}
        </h1>
        <div class="tw-w-500px tw-h-281px">
        </div>
      </div>
    </header>
    <main class="tw-flex-grow tw-flex tw-p-5 tw-text-base tw-text-left" v-html="html" />
    <nav class="tw-flex-grow-0">
      <ProgressIndicator />
    </nav>
  </div>
</template>

<script>
import { marked } from 'marked';
import ProgressIndicator from './ProgressIndicator.vue';

export default {
  name: 'ExamQuestion',
  components: {
    ProgressIndicator,
  },
  computed: {
    html() {
      return `<div>${marked(this.$props?.view?.content)}</div>`;
    },
  },
  props: {
    view: Object,
  },
  methods: {
    handleRenderExaminerCamera(isViewVideo) {
      if (isViewVideo) {
        this.$socket.emit('renderExaminerCamera', 'partial');
      } else {
        this.$socket.emit('renderExaminerCamera', 'none');
      }
    },
  },
  mounted() {
    this.handleRenderExaminerCamera(this.view.video);
  },
  watch: {
    view: {
      handler() {
        this.handleRenderExaminerCamera(this.view.video);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>

import DailyIframe, { DailyCall } from '@daily-co/daily-js';

interface DailySessionMeta {
  roomUrl: string;
  clientToken: string;
  userName: string;
  recordOnly?: boolean;
}

class Daily {
  private static dailyCall: DailyCall;

  private static isDailyCallCreated = false;

  private static isJoined = false;

  public static getDaily(): DailyCall {
    if (!this.dailyCall) {
      throw new Error('Daily call is not created');
    }

    return this.dailyCall;
  }

  public static getIsDailyCallCreated(): boolean {
    return this.isDailyCallCreated;
  }

  public static createDailyCall(): void {
    this.isDailyCallCreated = true;
    this.dailyCall = DailyIframe.createCallObject();
  }

  public static joinDailyCall(dailySessionMeta: DailySessionMeta | undefined): void {
    this.getDaily().join({
      url: dailySessionMeta?.roomUrl,
      token: dailySessionMeta?.clientToken,
      userName: dailySessionMeta?.userName,
    });
  }

  public static destroyDailyFrame(): void {
    this.isDailyCallCreated = false;
    this.dailyCall.destroy();
  }
}

export default Daily;

<template>
  <div class="tw-flex tw-flex-grow tw-items-center tw-justify-center tw-column tw-flex-col">
    <h1 v-if="view.content" class="tw-text-4xl tw-font-bold tw-uppercase tw-mb-1.5">
      {{ view.content }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'ExamIntro',
  props: {
    view: Object,
  },
  methods: {
    handleRenderExaminerCamera(isViewVideo) {
      if (isViewVideo) {
        this.$socket.emit('renderExaminerCamera', 'full');
      } else {
        this.$socket.emit('renderExaminerCamera', 'none');
      }
    },
  },
  mounted() {
    this.handleRenderExaminerCamera(this.view.video);
  },
  watch: {
    view: {
      handler() {
        this.handleRenderExaminerCamera(this.view.video);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

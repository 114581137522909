const APP_SOCKET_IO_URL: string = window?.['_env_']?.VUE_APP_SOCKET_IO_URL;
const APP_API_URL: string = window?.['_env_']?.VUE_APP_API_URL;

const ENV: string = window?.['_env_']?.VUE_APP_ENV;

const VECTOR_URL: string = window?.['_env_']?.VUE_APP_VECTOR_URL;

const VECTOR_API_KEY: string = window?.['_env_']?.VUE_APP_VECTOR_API_KEY;

export {
  APP_API_URL,
  APP_SOCKET_IO_URL,
  ENV,
  VECTOR_URL,
  VECTOR_API_KEY,
};

<script>
import axios from 'axios';
import { LogLevel } from '@/interfaces';
import { ENV, VECTOR_API_KEY, VECTOR_URL } from '@/consts/env';

export default {
  provide() {
    return {
      log: this.log,
    };
  },
  data() {
    return {
      isOffline: false,
      unsentLogs: [],
    };
  },
  methods: {
    handleOnline() {
      this.isOffline = false;
    },
    handleOffline() {
      this.isOffline = true;
    },
    log(log) {
      if (this.isOffline) {
        this.unsentLogs.push(log);
        return;
      }

      const logData = {
        logLevel: log?.logLevel || LogLevel.INFO,
        timestamp: new Date().toISOString(),
        message: log?.message || '',
        app: 'icao4u-exam-app',
        env: ENV || 'dev',
        context: log?.context || {},
      };

      if (this.$socket.connected) {
        this.$socket.emit('log', logData);
        return;
      }

      if (VECTOR_URL && VECTOR_API_KEY) {
        const urlParams = new URLSearchParams(window.location.search);
        const examId = urlParams.get('examId');

        axios.post(
          VECTOR_URL,
          {
            ...logData,
            context: { ...logData.context, examId },
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${VECTOR_API_KEY}`,
            },
          },
        );
      }
    },
  },
  mounted() {
    this.$socket.on('connect', () => this.log({
      message: 'Connected to socket.io server',
      context: { action: 'socket-connected' },
    }));
    this.$socket.on('disconnect', () => this.log({
      message: 'Disconnected from socket.io server',
      context: { action: 'socket-disconnected' },
    }));

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  },
  watch: {
    isOffline: {
      handler() {
        if (!this.isOffline) {
          this.unsentLogs.forEach(log => this.log(log));
          this.unsentLogs = [];
        }
      },
    },
  },
  beforeUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  },
};
</script>

<template>
  <slot></slot>
</template>

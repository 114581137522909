<script>
export default {
  name: 'ExamMark',
  props: ['mark'],
};
</script>

<template>
  <div class="tw-absolute tw-text-mark tw-font-arial">{{ mark }}</div>
</template>
